import AddIcon from '@mui/icons-material/Add'
import { useAuth0 } from '@auth0/auth0-react'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Button,
  CircularProgress,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IAccount, IAlert } from '../../interfaces'
import { capitalizeFirstLetter, envParams, getOptions, numberFormat } from '../../utils'
import { CrDialogDeleteAlert } from './CrDialogDeleteAlert'

const fromMinutesToString = (minutes: number): string => {
  let string = ''
  if (minutes === 1) {
    string = `${minutes} minuto`
  } else if (minutes > 1 && minutes <= 30) {
    string = `${minutes} minutos`
  } else if (minutes === 60) {
    string = `${numberFormat(minutes / 60, 0)} hora`
  } else if (minutes > 60 && minutes <= 720) {
    string = `${numberFormat(minutes / 60, 0)} houras`
  } else if (minutes === 1440) {
    string = `${numberFormat(minutes / 60 / 24, 0)} día`
  }
  return string
}

const updateAlert = async (
  controlRiskServerUrl: string,
  token: string,
  alertId: string,
  enabled: boolean,
): Promise<void> => {
  try {
    const url = `${controlRiskServerUrl}/alert`
    const payload = {
      id: alertId,
      enabled: enabled
    }
    const options = getOptions(token)
    await axios.patch(
      url,
      payload,
      options 
    )
  } catch (error) {
    console.log(new Error('Error creating Alerts'))
    console.error(error)
  }
}

export function CrTableAlerts ({
  accounts,
  alerts,
  setAlerts,
  loadingDashboard,
  handleOpenDialogAlerts,
  loadingNewAlert,
  setLoadingNewAlert
}: {
  accounts: IAccount[] | undefined
  alerts: IAlert[] | undefined
  setAlerts: React.Dispatch<React.SetStateAction<IAlert[] | undefined>>
  loadingDashboard: boolean
  handleOpenDialogAlerts: () => void
  loadingNewAlert: boolean,
  setLoadingNewAlert: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [switches, setSwitches] = useState<Array<{ id: string, checked: boolean }>>([])
  const [openDialogDeleteAlert, setOpenDialogDeleteAlert] = useState<boolean>(false)
  const [alertIdToBeDelete, setAlertIdToBeDelete] = useState<string>('')
  const { getAccessTokenSilently } = useAuth0()
  
  useEffect(() => {
    const initialSwitches = alerts ?
    alerts.map((alert) => {
      return {
        id: alert.id,
        checked: alert.enabled
      }
    }) :
    []
    setSwitches(initialSwitches)
  }, [alerts])

  const handleChangeSwitch = (alertId: string) => async (event: any) => {
    const enabled = event.target.checked
    const controlRiskServerUrl = envParams.HOSTNAME_SERVER
    const token = await getAccessTokenSilently()
    try {
      await updateAlert(
        controlRiskServerUrl,
        token,
        alertId,
        enabled
      )
      const newSwitches = switches.map((switchItem) =>
        switchItem.id === alertId ? { ...switchItem, checked: enabled } : switchItem
      )
      setSwitches(newSwitches)
      const newAlerts = alerts?.map((alertItem) =>
        alertItem.id === alertId ? { ...alertItem, enabled: enabled } : alertItem
      )
      setAlerts(newAlerts)
    } catch (error) {
      // TODO
      console.log(error)
    }
  }

  const handleOpenDialogDeleteAlert = (alertId: string): void => {
    setAlertIdToBeDelete(alertId)
    setOpenDialogDeleteAlert(true)
  }

  return (
    <>
      <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>
        Alertas
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: 'grey.800' }}>
            <TableRow>
              <TableCell>Cuenta</TableCell>
              <TableCell>Cuando</TableCell>
              <TableCell>Avísame</TableCell>
              <TableCell align="center" sx={{ width: 64 }}/>
              <TableCell align="center" sx={{ width: 64 }}/>
            </TableRow>
          </TableHead>
          <TableBody>
          {loadingDashboard ? (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </TableCell>
            </TableRow>
          ) : (
            !alerts ? (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell colSpan={5} align="center" sx={{ color: 'error.main' }}>
                  Lo sentimos, parece que hay un error
                </TableCell>
              </TableRow>
            ) : !alerts.length ? (
              <>
                {(!accounts || !accounts.length) ? (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell colSpan={7} align="center">
                      Parece que no tienes ninguna cuenta activa
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell colSpan={5} align="center">
                      Crea una nueva Alerta
                      &nbsp;
                      <Button
                        variant="contained"
                        onClick={handleOpenDialogAlerts}
                        color="primary"
                        disabled={loadingNewAlert}
                      >
                        {
                          loadingNewAlert
                          ? <CircularProgress size={24} color="inherit" />
                          : <AddIcon />
                        }
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <>
                {alerts.map((alert: IAlert) => (
                  <TableRow key={alert.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      {alert.accountName}&nbsp;[&nbsp;{alert.accountNumber}&nbsp;]
                    </TableCell>
                    <TableCell>
                      el {capitalizeFirstLetter(alert.when)} supera el {alert.achieves} {alert.when !== 'leverage' ? '%' : ''}
                    </TableCell>
                    <TableCell>
                      cada {fromMinutesToString(alert.every)}
                    </TableCell>
                    <TableCell align='center'>
                      <Switch
                        key={alert.id}
                        checked={switches.find((switchItem) => switchItem.id === alert.id)?.checked}
                        onChange={handleChangeSwitch(alert.id)}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => handleOpenDialogDeleteAlert(alert.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={5} align="center">
                    Crea una nueva Alerta
                    &nbsp;
                    <Button
                      variant="contained"
                      onClick={handleOpenDialogAlerts}
                      color="primary"
                      disabled={loadingNewAlert}
                    >
                      {
                        loadingNewAlert
                        ? <CircularProgress size={24} color="inherit" />
                        : <AddIcon />
                      }
                    </Button>
                  </TableCell>
                </TableRow>}
              </>
            )
          )}
          </TableBody>
        </Table>
      </TableContainer>

      <CrDialogDeleteAlert
        alertIdToBeDelete = { alertIdToBeDelete }
        setAlertIdToBeDelete = { setAlertIdToBeDelete }
        alerts = { alerts }
        setAlerts = { setAlerts }
        openDialogDeleteAlert = { openDialogDeleteAlert }
        setOpenDialogDeleteAlert = { setOpenDialogDeleteAlert }
      />
    </>
  )
}
