import { useAuth0 } from '@auth0/auth0-react'
import {
  AlertColor,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import axios from 'axios'
import * as React from 'react'
import { Dispatch, useState } from 'react'
import { IAccount, IAlert, IPostAlert } from '../../interfaces'
import { addAccountNameToAlert, envParams, getOptions } from '../../utils'

const createAlert = async (
  controlRiskServerUrl: string,
  token: string,
  alert: IPostAlert,
): Promise<IAlert | undefined> => {
  const url = `${controlRiskServerUrl}/alert`
  const options = getOptions(token)
  try {
    const response = await axios.post(
      url,
      alert,
      options
    )
    return response.data
  } catch (error) {
    console.log(new Error('Error creating Alerts'))
    console.error(error)
  }
}

export function CrDialogNewAlert ({
  accounts,
  alerts,
  setAlerts,
  openDialogNewAlert,
  setOpenDialogNewAlert,
  handleOpenSnackbar
}: {
  accounts: IAccount[] | undefined
  alerts: IAlert[] | undefined
  setAlerts: Dispatch<React.SetStateAction<IAlert[] | undefined>>
  openDialogNewAlert: boolean
  setOpenDialogNewAlert: Dispatch<React.SetStateAction<boolean>>
  handleOpenSnackbar: (type: AlertColor, message: string) => void
}) {
  const [account, setAccount] = useState<string | undefined>(undefined)
  const [when, setWhen] = useState<string | undefined>(undefined)
  const [achieves, setAchieves] = useState<number | undefined>(undefined)
  const [every, setEvery] = useState<number | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const { getAccessTokenSilently } = useAuth0()

  const handleChangeAccount = (event: any) => {
    setAccount(event.target.value)
  }
  const handleChangeWhen = (event: any) => {
    setWhen(event.target.value)
  }
  const handleChangeAchieves = (event: any) => {
    setAchieves(event.target.value)
  }
  const handleChangeEvery = (event: any) => {
    setEvery(event.target.value)
  }
  const handleCloseDialogNewAlert = (): void => {
    setOpenDialogNewAlert(false)
    resetDialogNewAlert()
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    
    const alert: IPostAlert = {
      accountNumber: account || '',
      when: when || '',
      achieves: parseFloat(achieves?.toString() || '0'),
      every: every || 0,
      enabled: false,
      nextNotify: new Date('1970-01-01T00:00:00.000Z')
    }
    const controlRiskServerUrl = envParams.HOSTNAME_SERVER
    const token = await getAccessTokenSilently()
    const createdAlert = await createAlert(
      controlRiskServerUrl,
      token,
      alert,
    )
    
    if (createdAlert) {
      alerts?.push(createdAlert)
      addAccountNameToAlert(alerts, accounts)
      setAlerts(alerts)
      handleCloseDialogNewAlert()
      resetDialogNewAlert()
    } else {
      handleOpenSnackbar('error', 'Imposible crear la Alerta en este momento. Vuelve a intentarlo!')
      setLoading(false)
    }
  }

  const resetDialogNewAlert = () => {
    setAccount(undefined)
    setWhen(undefined)
    setAchieves(undefined)
    setEvery(undefined)
    setLoading(false)
  }

  const menuItems = []
  for (let i = 1; i <= 100; i++) {
    menuItems.push(
      <MenuItem key={i} value={i}>
        el {i} %
      </MenuItem>
    )
  }

  return (
    <>
      <Dialog
        open = { openDialogNewAlert }
        onClose = { handleCloseDialogNewAlert }
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle>
          Crea una nueva Alerta
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="select-label-account">Cuenta</InputLabel>
            <Select
              labelId="select-label-account"
              id="select-account"
              value={account}
              label="Cuenta"
              onChange={handleChangeAccount}
            >
              {accounts && accounts.map((account) =>
                <MenuItem key={account.number} value={account.number}>
                  {account.name} [ {account.number} ]
                </MenuItem>
              )}
            </Select>
          </FormControl>
          
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="select-label-when">Cuando</InputLabel>
            <Select
              labelId="select-label-when"
              id="select-when"
              value={when}
              label="Cuando"
              onChange={handleChangeWhen}
            >
              <MenuItem key="drawdown" value={'drawdown'}>el Drawdown</MenuItem>
              <MenuItem key="floating" value={'floating'}>el Flotante</MenuItem>
              <MenuItem key="leverage" value={'leverage'}>el Apalancamiento</MenuItem>
            </Select>
          </FormControl>

          {(when && when === 'leverage') ?
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="text-field-achieves"
                value={achieves}
                label="Supera"
                onChange={handleChangeAchieves}
                variant="outlined"
              />
            </FormControl>
          :
            <FormControl fullWidth sx={{ my: 2 }}>
              <InputLabel id="select-label-achieves">Supera</InputLabel>
              <Select
                labelId="select-label-achieves"
                id="select-achieves"
                value={achieves}
                label="Supera"
                onChange={handleChangeAchieves}
              >
                {menuItems}
              </Select>
            </FormControl>
          }

          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="select-label-every">Avísame</InputLabel>
            <Select
              labelId="select-label-every"
              id="select-every"
              value={every}
              label="Avísame"
              onChange={handleChangeEvery}
            >
              <MenuItem key={1} value={1}>cada 1 minuto</MenuItem>
              <MenuItem key={5} value={5}>cada 5 minutos</MenuItem>
              <MenuItem key={10} value={10}>cada 10 minutos</MenuItem>
              <MenuItem key={15} value={15}>cada 15 minutos</MenuItem>
              <MenuItem key={30} value={30}>cada 30 minutos</MenuItem>
              <MenuItem key={60} value={60}>cada 1 hora</MenuItem>
              <MenuItem key={120} value={120}>cada 2 horas</MenuItem>
              <MenuItem key={360} value={360}>cada 6 horas</MenuItem>
              <MenuItem key={720} value={720}>cada 12 horas</MenuItem>
              <MenuItem key={1440} value={1440}>cada 1 día</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCloseDialogNewAlert} color="inherit">Anular</Button>
          <Button variant="contained" type="submit" onClick={handleSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Crear'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}